import React, { Component } from 'react'
import styled from '@emotion/styled'
import peg, { responsive } from 'src/pegTheme'
import { css } from '@emotion/core'
import TinyCrossfade from 'react-tiny-crossfade'
import { Preload } from 'react-preload'

import computer from './computer.png'
import search from './search.png'
import report from './report.png'
import retargeting from './retargeting.png'
import more from './more.png'
import tick from './tick.svg'
import arrowRight from './arrow-right.png'

export default class Features extends Component {
  state = {
    activeSlide: 'search'
  }

  selectSlide = activeSlide => {
    this.setState({ activeSlide })
  }

  nextSlide = () => {
    const { activeSlide } = this.state

    const slides = ['search', 'report', 'retargeting', 'more']
    const nextSlide = slides[(slides.indexOf(activeSlide) + 1) % 4]
    // console.log(next)
    this.selectSlide(nextSlide)
  }

  slideCopy = () => {
    const { activeSlide } = this.state
    const feature = text => (<IndividualFeature><FeatureTick src={ tick } />{ text }</IndividualFeature>)

    switch(activeSlide) {
      case 'search':
        return (
          <FeatureCopy>
            <SubHeading>Identify perfect partners</SubHeading>
            <Text>
              Peg’s advanced search algorithms makes it beautifully easy to find YouTube & Instagram influencers across every category.
            </Text>
            <FeatureList>
              { feature('Keywords') }
              { feature('Audience demographics') }
              { feature('Brand affinity') }
              { feature('Engagement levels') }
            </FeatureList>
          </FeatureCopy>
        )
      case 'report':
        return (
          <FeatureCopy>
            <SubHeading>Measure what matters</SubHeading>
            <Text>
              Peg’s advanced reporting makes it possible to measure bottom-of-the-funnel metrics such as sales, app downloads and revenue.
            </Text>
            <FeatureList>
              { feature('Views & engagements') }
              { feature('Revenues and ROI') }
              { feature('Sales & downloads') }
              { feature('Data exporting') }
            </FeatureList>
          </FeatureCopy>
          )
      case 'retargeting':
        return (
          <FeatureCopy>
            <SubHeading>Influencers + Retargeting</SubHeading>
            <Text>
              Peg makes it possible to retarget audiences who have engaged with influencer content and measure the results in one place.
            </Text>
            <FeatureList>
              { feature('Trackable links') }
              { feature('Google Ads') }
              { feature('Facebook Ads') }
              { feature('+ many more') }
            </FeatureList>
          </FeatureCopy>
        )
      case 'more':
        return (
          <FeatureCopy>
            <SubHeading>So much more</SubHeading>
            <Text>
              Despite looking deceptively simple, Peg is jam-packed with advanced features, AI algorithms and machine learning models to make influencer marketing so much easier.
            </Text>
            <FeatureList>
              { feature('Brand mentions') }
              { feature('Email addresses') }
              { feature('Brand collaborations') }
              { feature('Data exporting') }
              { feature('Brand safety checker') }
              { feature('Friendly pricing') }
            </FeatureList>
          </FeatureCopy>
        )
      default:
        return null
    }
  }

  render() {
    const { activeSlide } = this.state

    return <FeaturesBody>
      <FeatureImages>
        <ComputerContainer>
          <Computer src={ computer } />
          <TinyCrossfade duration={ 500 } disableInitialAnimation className='crossfade'>
            {{
              search: <ComputerBackground slide='search' key='search' />,
              report: <ComputerBackground slide='report' key='report' />,
              retargeting: <ComputerBackground slide='retargeting' key='retargeting' />,
              more: <ComputerBackground slide='more' key='more' />
            }[activeSlide] }
          </TinyCrossfade>

          <Preload images={ [search, report, retargeting, more] }>
            <TinyCrossfade duration={ 1000 } className='crossfade'>
              {{
                search: <Slide src={ search } key='search' />,
                report: <Slide src={ report } style={{ top: '120px' }} key='report' />,
                retargeting: <Slide src={ retargeting } key='retargeting' />,
                more: <Slide src={ more } style={{ top: '60px' }} key='more' />,
              }[activeSlide] }
            </TinyCrossfade>
          </Preload>
        </ComputerContainer>
      </FeatureImages>

      <FeatureCopyContainer>
        <Tabs>
          <Tab onClick={ () => this.selectSlide('search') } active={ activeSlide === 'search' }>Search</Tab>
          <Tab onClick={ () => this.selectSlide('report') } active={ activeSlide === 'report' }>Report</Tab>
          <Tab onClick={ () => this.selectSlide('retargeting') } active={ activeSlide === 'retargeting' }>Retarget</Tab>
          <Tab onClick={ () => this.selectSlide('more') } active={ activeSlide === 'more' }>More</Tab>
        </Tabs>
        <FeatureCopyBody>
          { this.slideCopy() }
          <NextFeature>
            <ArrowRight src={ arrowRight } onClick={ this.nextSlide } />
          </NextFeature>
        </FeatureCopyBody>
      </FeatureCopyContainer>
    </FeaturesBody>
  }
}

const FeaturesBody = styled.div`
  display: flex;
  width: 100%;
  ${ responsive('padding', { d: '80px 0 150px', m: '20px 0 40px' }) }
  ${ responsive('margin-top', { d: '60px', m: peg.spacing('m') }) }
`

const FeatureImages = styled.div`
  ${ responsive('display', { d: 'block', m: 'none' }) }
  width: 50%;
  flex-shrink: 0;
`

const ComputerContainer = styled.div`
  display: inline-block;
  position: relative;
  background: linear-gradient(to right, ${ peg.color('blue') }, ${ peg.color('blue.dark') });

  .crossfade {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Computer = styled.img`
  position: relative;
  z-index: 1;
  max-height: 500px;
  pointer-events: none;
  margin-bottom: -10px;
`

const ComputerBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  width: 350px;
  height: 450px;
  transition: opacity 1s ease;

  &.before-enter {
    opacity: 0;
  }

  &.entering {
    opacity: 1;
    transition: all 1s ease;
  }

  &.before-leave {
    opacity: 1;
  }

  &.leaving {
    opacity: 0;
    transition: all 1s ease;
  }

  background: ${ props => ({
    search: `linear-gradient(to right, #80D0E3, #6F91EB)`,
    report: `linear-gradient(to right, #F94B94, #FBCBC5)`,
    retargeting: `linear-gradient(to right, #58D1AB, #51C6E5)`,
    more: `linear-gradient(to right, #D470A6, #908BC9)`,
  }[props.slide]) };
`

const Slide = styled.img`
  width: 550px;
  position: absolute;
  left: 20px;
  top: 100px;
  transition: all 0.7s ease;
  z-index: 2;

  &.before-enter {
    transform: translateX(-20px);
    opacity: 0;
  }

  &.entering {
    transform: translateX(0);
    opacity: 1;
  }

  &.before-leave {
    transform: translateX(0);
    opacity: 1;
  }

  &.leaving {
    transform: translateX(20px);
    opacity: 0;
  }
`

const FeatureCopyContainer = styled.div`
  ${ responsive('width', { d: '50%', m: '100%' }) }
  max-width: 600px;
  ${ responsive('padding', { d: '0', m: `0 ${ peg.spacing('m') }` }) }
`

const Tabs = styled.div`
  display: flex;
`

const Tab = styled.span`
  color: ${ peg.color('grey') };
  opacity: 0.7;
  font-weight: ${ peg.get('fontWeight.bold') };
  display: inline-block;
  padding: ${ peg.spacing('l') } ${ peg.spacing('m') };
  text-transform: uppercase;
  font-size: ${ peg.fs('s') };
  position: relative;
  margin-right: ${ peg.spacing('m') };
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1.0;
  }

  &::after {
    content: ' ';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    transform: scaleY(0);
    transform-origin: bottom;
    background: ${ peg.color('blue') };
    transition: transform 0.3s linear;
  }

  ${ props => props.active && css`
    color: ${ peg.color('grey.dark') };
    opacity: 1;

    &::after {
      transform: scaleY(1);
    }
  `}
`

const FeatureCopy = styled.div`
  ${ responsive('padding', { d: `${ peg.spacing('xl') } 0`, m: '0' }) }
`

const Text = styled.p`
  line-height: 26px;
  font-size: ${ peg.fs('l') };
  margin-bottom: ${ peg.spacing('xl') };
  color: ${ peg.color('grey') };
`

const SubHeading = styled.h2`
  margin-bottom: ${ peg.spacing('s') };
  text-align: left;
  font-size: ${ peg.fs('headLg') };
`

const FeatureList = styled.ul`
  color: ${ peg.color('grey') };
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`

const IndividualFeature = styled.li`
  width: 50%;
  line-height: 150%;
  padding: 5px 0;
`

const FeatureTick = styled.img`
  width: 16px;
  vertical-align: -2px;
  margin-right: ${ peg.spacing('s') };
`

const FeatureCopyBody = styled.div`
  display: flex;
`

const NextFeature = styled.div`
  padding-top: 150px;
  padding-left: ${ peg.spacing('s') };
`

const ArrowRight = styled.img`
  width: 40px;
  cursor: pointer;
`
