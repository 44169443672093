import React from 'react'
import styled from '@emotion/styled'
import peg, { responsive } from 'src/pegTheme'
import { css } from '@emotion/core'
import { math } from 'polished'

import quoteBg from 'src/images/quote-bg.png'
import imhLogo from './imh-logo.png'
import fiveStars from './five-stars.svg'

export default () => (
  <ReviewBody>
    <ReviewCard>
      <ReviewerLogo src={ imhLogo } />

      <ScoreRow> Search <StarScore src={ fiveStars } /></ScoreRow>
      <ScoreRow dark>Management Tools <StarScore src={ fiveStars } /></ScoreRow>
      <ScoreRow>Interface/Ease of Use <StarScore src={ fiveStars } /></ScoreRow>
      <ScoreRow dark>Reporting/Analytics <StarScore src={ fiveStars } /></ScoreRow>
      <OverallScore>
        5.0
        <StarScore src={ fiveStars } />
      </OverallScore>
    </ReviewCard>

    <ReviewCard css={ quoteCard }>
      <div>
        <Quote>Using Peg feels like you’re using software that’s been around for decades.</Quote>
        <Quote>
          That’s not meant to say it’s slow or poorly designed (which you already figured out if you didn’t skip right ahead to this conclusion).
          It’s just that everything it does, it does well—really well—and so there’s this feeling you get that its creators have been doing this a long time and have seen it all.
        </Quote>
        <QuoteAuthor>Dave Eagle, Influencer Marketing Hub</QuoteAuthor>
      </div>
      <FullReviewLink href='https://influencermarketinghub.com/peg/' rel='noopener noreferrer' target='_blank'>
        Read the full review
      </FullReviewLink>
    </ReviewCard>
  </ReviewBody>
)

const ReviewBody = styled.div`
  display: flex;
  ${ responsive('margin-top', { d: '60px', m: peg.spacing('m') }) }
  ${ responsive('flex-direction', { d: 'row', m: 'column' }) }
`

const ReviewCard = styled.div`
  ${ responsive('width', { d: '600px', m: '100%' }) }
  padding: ${ peg.spacing('l') } 0;
  ${ responsive('margin', {
    d: `0 ${ peg.spacing('m') } ${ peg.spacing('m') }`,
    m: `0 0 ${ peg.spacing('l') }`
  }) }
  background: #fff;
  border-radius: ${ peg.get('borderRadius') };
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .25);
  color: ${ peg.color('grey.dark') };
`

const ReviewerLogo = styled.img`
  display: block;
  margin: ${ peg.spacing('m') } auto;
  width: 200px;
`

const ScoreRow = styled.div`
  padding: ${ peg.spacing('m') } ${ math(`${ peg.spacing('m') } * 2`) };
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${ peg.fs('m') };
  font-weight: ${ peg.get('fontWeight.normal') };
  ${ props => props.dark && css`background: ${ peg.color('grey.lighter') };` }
`

const StarScore = styled.img`
  width: 60px;
`

const OverallScore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: ${ peg.spacing('l') } ${ math(`${ peg.spacing('m') } * 2`) } ${ peg.spacing('s') };
  font-size: ${ peg.fs('title') };
  font-weight: ${ peg.get('fontWeight.light') };
`

const quoteCard = css`
  padding: ${ peg.spacing('m') } 30px ${ peg.spacing('xl') };
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url(${ quoteBg }) 90% 90% no-repeat #fff;
  background-size: 80px;
`

const Quote = styled.p`
  font-style: italic;
  font-size: 16px;
  color: ${ peg.color('grey.dark') };
  font-weight: ${ peg.get('fontWeight.light') };
`

const QuoteAuthor = styled.p`
  margin-top: 30px;
`

const FullReviewLink = styled.a`
  color: ${ peg.color('blue') };
  transition: color 0.3s ease;

  &:hover {
    color: ${ peg.color('blue.dark') };
  }
`
