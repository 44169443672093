import React from 'react'
import styled from '@emotion/styled'
import macbook from './macbook.png'
import useWindowScrollPosition from 'src/hooks/useWindowScrollPosition'

export default ({ src, speed = 10 }) => {
  const position = useWindowScrollPosition({ throttle: 100 })
  const offset = Math.min(position.y / speed, 30)

  return <MacbookContainer>
    <MacbookImage src={ macbook } />
    <Screenshot>
      <img src={ src } alt='' style={{
        transform: `translate3d(0, -${ offset }%, 0)`
      }} />
    </Screenshot>
  </MacbookContainer>
}

const MacbookContainer = styled.div`
  width: 580px;
  position: relative;
  margin: 0 -250px;
  z-index: 2;
`

const MacbookImage = styled.img`
  width: 100%;
`

const Screenshot = styled.div`
  position: absolute;
  top: 12px;
  left: calc(50% - 152px);
  background: #f4f4f4;
  width: 290px;
  height: 168px;
  overflow: hidden;

  img {
    transition: transform 0.3s ease;
    max-width: 100%;
  }
`
