import React from 'react'
import styled from '@emotion/styled'
import peg, { responsive } from 'src/pegTheme'

import quoteBg from 'src/images/quote-bg.png'
import fran from './fran.jpg'

export default () => (
  <QuoteContainer>
    <TestifierThumbnail src={ fran } />
    <QuoteText>
      Now we wonder how we ever lived without Peg! It’s really become a must-have tool... Peg is super easy to use and lovely to look at.
    </QuoteText>
    <TestifierDetails>
      Fran Derry, Board Director at Iris PR
    </TestifierDetails>
  </QuoteContainer>
)

const QuoteText = styled.p`
  font-weight: ${ peg.get('fontWeight.light') };
  font-style: italic;
  font-size: ${ peg.fs('headSm') };
  -webkit-font-smoothing: antialiased;

  color: ${ peg.color('grey.dark') };
  text-align: center;
  line-height: 160%;
  margin: 0 auto;

  ${ responsive('width', { d: '800px', m: '100%' }) }
  ${ responsive('padding', { d: 'auto', m: `0 ${ peg.spacing('l') }` }) }
`

const QuoteContainer = styled.div`
  background: url(${ quoteBg }) no-repeat;
  background-size: 80px;
  background-size: 80px;
  background-position: bottom 50px right 20px;
  width: 100%;
  max-width: 1200px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::after {
    content: ' ';
    transform: rotateY(0.5turn);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(${ quoteBg }) no-repeat;
    background-size: 80px;
    background-position: top 50px right 20px;
    pointer-events: none;
  }
`

const TestifierThumbnail = styled.img`
  border: 4px solid #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  width: 80px;
  height: 80px;
  border-radius: 80px;
  margin-bottom: ${ peg.spacing('l') };
`

const TestifierDetails = styled.p`
  margin-top: ${ peg.spacing('l') };
`