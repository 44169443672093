import React, { Component } from 'react'
import styled from '@emotion/styled'
import ClientCard from './ClientCard'
import peg, { responsive } from 'src/pegTheme'

import beryl from './beryl.png'
import chime from './chime.png'
import ea from './ea.png'
import goodrelations from './goodrelations.png'
import google from './google.png'
import havas from './havas.png'
import iris from './iris.png'
import lego from './lego.png'
import loreal from './loreal.png'
import msix from './msix.png'
import neom from './neom.png'
import ogilvy from './ogilvy.png'
import okcool from './okcool.png'
import omd from './omd.png'
import popcorn from './popcorn.png'
import spider from './spider.png'

const logos = [
  [popcorn, ogilvy],
  [ea, msix],
  [spider, okcool],
  [lego, beryl],
  [neom, chime],
  [iris, loreal],
  [omd, goodrelations],
  [google, havas],
]

let interval, timeout

export default class ClientLogos extends Component {
  state = {
    flipped: Array.from(logos).fill(false)
  }

  componentDidMount() {
    const logoOrder = [4, 1, 7, 0, 2, 6, 3, 5]
    let index = 0

    interval = window.setInterval(() => {
      let flipped = [...this.state.flipped]
      flipped[logoOrder[index]] = !flipped[logoOrder[index]]
      this.setState({ flipped })

      timeout = window.setTimeout(() => {
        flipped[logoOrder[index + 1]] = !flipped[logoOrder[index + 1]]
        this.setState({ flipped })
        index = (index + 2) % 8
      }, 1200)
    }, 3000)
  }

  componentWillUnmount() {
    window.clearInterval(interval)
    window.clearTimeout(timeout)
  }

  render() {
    const { flipped } = this.state

    return <CardContainer>
      { logos.map(([initial, alternate], i) => (
        <ClientCard { ...{ initial, alternate, isFlipped: flipped[i] } } key={ i } />
      )) }
    </CardContainer>
  }
}

const CardContainer = styled.div`
  ${ responsive('width', { d: '900px' , m: '100%' })}
  ${ responsive('max-width', { d: 'none' , m: '600px' })}
  ${ responsive('margin-top', { d: peg.spacing('m') , m: 0 })};
  display: flex;
  flex-flow: row wrap;
`
