import { useState, useEffect } from 'react'
import { throttle, noop } from 'lodash'

let supportsPassive = false
try {
  var opts = Object.defineProperty({}, 'passive', {
    get: function() {
      supportsPassive = true
      return true
    },
  })
  window.addEventListener('testPassive', null, opts)
  window.removeEventListener('testPassive', null, opts)
} catch (e) {}

const getPosition = () => (
  (typeof window === `undefined`) ? { x: 0, y: 0 } : {
    x: window.pageXOffset,
    y: window.pageYOffset,
  }
)

export default function useWindowScrollPosition(options = { throttle: 100 }) {
  const [position, setPosition] = useState(getPosition())

  useEffect(() => {
    const handleScroll = throttle(() => {
      setPosition(getPosition())
    }, options.throttle)

    if(typeof window === `undefined`) return noop

    window.addEventListener(
      'scroll',
      handleScroll,
      supportsPassive ? { passive: true } : false
    )

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [throttle])

  return position
}
