import React from 'react'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import peg, { responsive } from 'src/pegTheme'
import { Helmet } from 'react-helmet'
import urls from 'src/urls.json'
import { Title, SubHeading, StrapLine } from 'src/components/typography'
import checkMobile from 'src/components/checkMobile'

import Layout from 'src/components/Layout'
import Macbook from 'src/components/devices/Macbook'
import Mac from 'src/components/devices/Mac'
import TeaserVideo from 'src/components/teaserVideo/TeaserVideo'

import search from 'src/images/search.png'
import report from 'src/images/report.png'
import profile from 'src/images/profile.png'
import pegWhite from 'src/images/peg-white.svg'

import ClientLogos from 'src/components/clients/ClientLogos'
import Review from 'src/components/review/Review'
import Features from 'src/components/features/Features'
import Testimonial from 'src/components/testimonial/Testimonial'

import { Link } from 'gatsby'
import RaisedButton from 'src/components/buttons/RaisedButton'

export default checkMobile(({ isMobile, callToAction = 'Request a demo' }) => (
  <Layout>
    <Helmet>
      <meta name='description' content='Peg is a YouTube and Instagram influencer marketing tool and platform designed to help brands & YouTube creators collaborate and produce beautiful content' />
    </Helmet>

    <Global
      styles={css`
        .headroom--unfixed > div {
          border-bottom-color: transparent;
        }
      `}
    />

    <Hero>
      <TopTitle>Influencer marketing software for performance-driven companies.</TopTitle>
      <CTAButton to='/current'>{ callToAction }</CTAButton>

      <DevicesContainer>
        <Macbook src={ search } speed={ 25 } />

        { !isMobile && <>
          <Mac src={ profile } speed={ 30 } />
          <Macbook src={ report } speed={ 25 } />
        </> }
      </DevicesContainer>
    </Hero>

    <LandingPageSection css={ greySection }>
      <SubHeading>One tool. Does it all.</SubHeading>
      <StrapLine>Whether searching for ultra-niche influencers, or measuring bottom-of-the-funnel campaign metrics, Peg saves you oodles of time.</StrapLine>
      <TeaserVideo />
    </LandingPageSection>

    <LandingPageSection>
      <SubHeading>One tool. For them all.</SubHeading>
      <StrapLine>Brands. Agencies. Big. Small. The one thing the 1,700 companies using Peg have in common is being driven by real results. Not vanity metrics.</StrapLine>
      <ClientLogos />
    </LandingPageSection>

    <LandingPageSection css={ blueSection }>
      <SubHeading>One tool. Above them all.</SubHeading>
      <StrapLine>Influencer Marketing Hub independently reviewed 31 influencer platforms. Peg came out on top with 5 stars in every category.</StrapLine>
      <Review />
    </LandingPageSection>

    <LandingPageSection noPadding>
      <Features />
    </LandingPageSection>

    <LandingPageSection css={ greySection }>
      <Testimonial />
    </LandingPageSection>
    
    <LandingPageSection css={ blueSection }>
      <PegIcon src={ pegWhite } alt='' />
      <DemoSubHeading>Request a free demo</DemoSubHeading>
      <CTAButton to='/current'>{ callToAction }</CTAButton>
    </LandingPageSection>
  </Layout>
))

const TopTitle = styled(Title)`
  ${ responsive('padding', { d: `${ peg.spacing('s') } 0`, m: peg.spacing('s') }) }
`

const Hero = styled.div`
  ${ responsive('padding', { d: '60px 40px 0', m: '0' }) }
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to bottom, ${ peg.color('grey.lighter') }, ${ peg.color('grey.lighter') });
  background-size: 100% 100px;
  background-repeat: no-repeat;
  background-position-y: bottom;
`

const EmailFormSmallCopy = styled.p`
  margin: 0;
  font-size: ${ peg.fs('s') };
  font-weight: ${ peg.get('fontWeight.light') };
`

const DevicesContainer = styled.div`
  display: flex;
  ${ responsive('margin-top', { d: '100px', m: '50px' }) }
  min-height: 150px;
  align-items: flex-end;
  width: 100%;
  justify-content: center;
  overflow: hidden;
`

const LandingPageSection = styled.div`
  ${ props => props.noPadding ?
    css`padding: 0` :
    responsive('padding', { d: '80px 50px', m: '20px 10px 40px' }) }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`

const greySection = css`
  background-color: ${ peg.color('grey.lighter') };
`

const blueSection = css`
  background-color: #02a9cc;

  ${ SubHeading }, ${ StrapLine } {
    color: #fff;
  }

  ${ StrapLine } {
    font-weight: 300;
  }
`

const PegIcon = styled.img`
  width: 50px;
  height: 50px;
`

const DemoSubHeading = styled(SubHeading)`
  color: #fff;
  margin-bottom: ${ peg.spacing('l') };
`

const LoginPrompt = styled.p`
  font-size: ${ peg.fs('s') };
  color: #fff;
  a { color: #fff; }
`

const CTAButton = styled(RaisedButton.withComponent(Link))`
  padding: ${ peg.spacing('s') } ${ peg.spacing('l') };
  margin-bottom: 1px;
  white-space: nowrap;
`
