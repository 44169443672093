import React, { Component } from 'react'
import styled from '@emotion/styled'
import checkMobile from 'src/components/checkMobile'

import FullscreenVideo from './FullscreenVideo'
import fullMp4 from './full.mp4'
import teaserMp4 from './teaser.mp4'
import teaser from './teaser.webm'
import play from './play.png'
import poster from './poster.png'

import peg, { responsive } from 'src/pegTheme'
import { rgba, math } from 'polished'

class TeaserVideo extends Component {
  componentDidMount() {
    // Don't render this component during the build phase
    this.setState({ renderable: true })
  }

  state = {
    renderable: false,
    playFullVideo: false
  }

  render() {
    const { renderable, playFullVideo } = this.state
    const { isMobile } = this.props

    if(!renderable) return null

    if(isMobile) {
      return (
        <VideoContainer>
          <video key='mobileVideo' poster={ poster } controls>
            <source key='fullMp4' src={ fullMp4 } type='video/mp4' />
          </video>
        </VideoContainer>
      )
    } else {
      return (
        <VideoContainer>
          <video key='teaserVideo' autoPlay muted loop playsInline poster={ poster }>
            <source key='teaserMp4' src={ teaserMp4 } type='video/mp4' />
            <source key='teaser' src={ teaser } type='video/webm' />
          </video>
          <Overlay onClick={ () => this.setState({ playFullVideo: true }) }>
            <PlayIcon src={ play } alt='Play' />
            Watch video
          </Overlay>
          { playFullVideo && <FullscreenVideo onExitFullscreen={ () => {
            this.setState({ playFullVideo: false })
          }} /> }
        </VideoContainer>
      )
    }
  }
}

const borderRadius = `border-radius: ${ math(`${ peg.get('borderRadius') } * 2`) };`

const VideoContainer = styled.div`
  ${ responsive('width', { d: '720px', m: '300px' }) }
  ${ responsive('height', { d: '405px', m: '168px' }) }
  margin: ${ peg.spacing('xl') } auto ${ peg.spacing('m') };
  ${ borderRadius }
  box-shadow: 0 30px 40px rgba(0, 0, 0, 0.07);
  position: relative;

  video {
    ${ borderRadius }
    width: 100%;
    height: 100%;
  }
`

const PlayIcon = styled.img`
  width: 55px;
  height: 55px;
  margin-bottom: ${ peg.spacing('l') };
  transition: transform 0.3s ease;
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${ rgba(peg.color('blue'), 0.75) };
  transition: background 0.3s ease;
  z-index: 1;
  ${ borderRadius }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: ${ peg.fs('l') };
  font-weight: ${ peg.get('fontWeight.normal') };
  cursor: pointer;

  &:hover {
    background: ${ rgba(peg.color('blue'), 0.6) };
    ${ PlayIcon } {
      transform: scale(1.1);
    }
  }
`

export default checkMobile(TeaserVideo)
