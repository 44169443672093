import React from 'react'
import styled from '@emotion/styled'
import imac from './imac.png'
import useWindowScrollPosition from 'src/hooks/useWindowScrollPosition'

export default ({ src, speed = 10 }) => {
  const position = useWindowScrollPosition({ throttle: 100 })
  const offset = Math.min(position.y / speed, 20)

  return <MacContainer>
    <MacImage src={ imac } />
    <Screenshot>
      <img src={ src } alt='' style={{
        transform: `translate3d(0, -${ offset }%, 0)`
      }} />
    </Screenshot>
  </MacContainer>
}

const MacContainer = styled.div`
  width: 600px;
  position: relative;
  margin-bottom: 20px;
`

const MacImage = styled.img`
  width: 100%;
`

const Screenshot = styled.div`
  position: absolute;
  top: 15px;
  left: calc(50% - 226px);
  background: #f4f4f4;
  width: 454px;
  height: 260px;
  overflow: hidden;

  img {
    transition: transform 0.5s ease;
    max-width: 100%;
  }
`
