import React, { Component, createRef } from 'react'
import fullMp4 from './full.mp4'
import full from './full.webm'
import fscreen from 'fscreen'

export default class FullscreenVideo extends Component {
  constructor(props) {
    super(props)
    this.videoRef = createRef()
  }

  onFullscreenChange = () => {
    if(!document.fullscreenElement && !document.webkitCurrentFullScreenElement) this.props.onExitFullscreen()
  }

  componentDidMount() {
    fscreen.requestFullscreen(this.videoRef.current)
    fscreen.addEventListener('fullscreenchange', this.onFullscreenChange)
    this.videoRef.current.addEventListener('ended', this.props.onExitFullscreen)
  }

  componentWillUnmount() {
    fscreen.removeEventListener('fullscreenchange', this.onFullscreenChange)
    this.videoRef.current.removeEventListener('ended', this.props.onExitFullscreen)
  }

  render() {
    return (
      <video style={{ width: '0px' }} ref={ this.videoRef } width='720' height='405' autoPlay controls>
        <source src={ fullMp4 } type='video/mp4' />
        <source src={ full } type='video/webm' />
      </video>
    )
  }
}
