import React from 'react'
import peg, { responsive } from 'src/pegTheme'
import styled from '@emotion/styled'
import TinyCrossfade from 'react-tiny-crossfade'

export default ({ initial, alternate, isFlipped }) => (
  <Card>
    <TinyCrossfade duration={ 1500 }>
      { isFlipped ? <CardInner key='initial'><Logo src={ initial } /></CardInner>
        : <CardInner key='alternate'><Logo src={ alternate } /></CardInner> }
    </TinyCrossfade>
  </Card>
)

const Card = styled.div`
  ${ responsive('width', { d: 'calc(25% - 20px)' , m: 'calc(50% - 20px)' })}
  ${ responsive('height', { d: '180px' , m: '80px' })}
  margin: ${ peg.spacing('s') };
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  border-radius: ${ peg.get('borderRadius') };
  position: relative;
`

const CardInner = styled.div`
  ${ responsive('padding', {
    d: `${ peg.spacing('xl') } 30px`,
    m: `${ peg.spacing('s') }`,
  })}
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.before-enter {
    opacity: 0;
  }

  &.entering {
    opacity: 1;
    transition: all 1.5s ease;
  }

  &.before-leave {
    opacity: 1;
  }

  &.leaving {
    opacity: 0;
    transition: all 1.5s ease;
  }
`

const Logo = styled.img`
  ${ responsive('max-height', { d: '100%' , m: '75%' })}
  ${ responsive('max-width', { d: '100%' , m: '75%' })}
`
